import React from 'react';

import { Button } from 'components-v2/shared/Button';
import Container from 'components-v2/shared/Container';
import Icon from 'components-v2/shared/Icon/Icon';
import { Title, Text } from 'components-v2/shared/Elements';
import { PetcoLogoSharedComponent } from 'components-v2/shared/PetcoLogoSharedComponent';

import './claims-thank-you.css';
import { globalConsts } from 'utils/constGlobal';
import { transformUSDintoPoints } from 'utils/TextUtils';

const isMobile = JSON.parse(localStorage.getItem(globalConsts.LOCALSTORE_MOBILE_KEY));

const ClaimsThankYou = ({
  isOptimusCustomer,
  palsRewardsValue,
  backToRedeemBenefits,
  toggles,
}) => (
  <div className={`ClaimsThankYouPage-v2 ${toggles?.ENABLE_UNIFIED_MEMBERSHIP && 'ClaimsThankYouPage-v2-UM'}`}>
    {!isMobile && (
      <PetcoLogoSharedComponent />
    )}
    <Container>
      {toggles?.ENABLE_UNIFIED_MEMBERSHIP ? <Icon name="successCheck-UM" /> : <Icon name="successCheck" />}

      <Title role="alert">Your vet exam receipt was submitted.</Title>

      <Text>
        You will receive an email in the next 48 hours when your{' '}
        {isOptimusCustomer
          ? `${transformUSDintoPoints(palsRewardsValue)} points in`
          : `$${palsRewardsValue}`}{' '}
        {toggles?.ENABLE_UNIFIED_MEMBERSHIP
          ? 'Vital Care Rewards'
          : 'Pals Rewards'}{' '}
        are available to load.
      </Text>

      <Button
        label="Return to Dashboard"
        className="return-dashboard"
        onClick={backToRedeemBenefits}
      />
    </Container>
  </div>
);

export default ClaimsThankYou;
