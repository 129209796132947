import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { VIEW_INDEX, navigationHistory, claims } from 'store/slices';
import {
  isLoading,
} from 'store';

import { submitClaim, fetchMembershipPlans, fetchLastClinicSelected } from 'store/actions';
import ClaimWrapper from './ClaimWrapper';
import ClaimsDate from './ClaimsDate/ClaimDate';
import ClaimsUploadInvoices from './ClaimsUploadInvoices';
import ClaimsUploadInvoicesV2 from './ClaimsUploadInvoices-v2/ClaimUploadInvoices';
import ClaimsThankYou from './ClaimsThankYou';
import ClaimsThankYouV2 from './ClaimsThankYou-v2';
import ClaimReview from './ClaimReview';
import ClaimReviewV2 from './ClaimReview-v2';
import ClaimClinicSelection from './ClaimsClinicSelection';
import ClaimClinicSelectionV2 from './ClaimsClinicSelection-v2/ClaimsClinicSelection-v2';

import { claimViewModel } from './viewModel';
import { adobeAnalyticsTags } from 'utils/constGlobal';
import { trackEvent } from 'utils';

const Claim = () => {
  const viewModel = useSelector((state) => claimViewModel(state));
  const loading = useSelector((state) => isLoading(state));
  const [savedClaim, setSavedClaim] = useState(false);
  const [callSaveClaim, setCallSaveClaim] = useState(false);
  const dispatch = useDispatch();

  const {
    step,
    petName,
    petType,
    planId,
    benefitId,
    claimClinic,
    claimDate,
    claimFile,
    claimPresignedObject,
    claimLastClinicSelected,
    planStartDate,
    palsRewardsValue,
    isOptimusCustomer,
    toggles,
  } = viewModel;

  const fetchLastClinicSelectedCallbackSuccess = useCallback((lastClinicSelected) => {
    dispatch(claims.actions.setLastClinicSelected(lastClinicSelected));
  }, [dispatch]);

  useEffect(() => {
    // Fetch Last Clinic Submitted By the User
    dispatch(fetchLastClinicSelected(planId, (lastClinicSelected) => {
      fetchLastClinicSelectedCallbackSuccess(lastClinicSelected);
    }, (error) => {
      console.error(error);
    }));
  }, [dispatch, fetchLastClinicSelectedCallbackSuccess, planId]);

  useEffect(() => {
    if (!loading && savedClaim) {
      trackEvent(adobeAnalyticsTags?.VETEXAM_RECEIPT_SUBMITTED);
      dispatch(navigationHistory.actions.goToClaimsThankYou());
      dispatch(navigationHistory.actions.purgeClaimExceptThanks());
    }
  }, [dispatch, loading, savedClaim]);

  useEffect(() => {
    const createClaimAsync = async () => {
      const claimFileObjectURL = claimFile.key;
      await dispatch(submitClaim(planId, benefitId, claimClinic, claimDate, claimFileObjectURL));
      setSavedClaim(true);
    };

    if (callSaveClaim) {
      createClaimAsync();
      setCallSaveClaim(false);
    }
  }, [dispatch, callSaveClaim, setCallSaveClaim, setSavedClaim, planId, benefitId, claimClinic, claimDate, claimFile]);

  const onClinicSelectionCompletion = () => {
    dispatch(navigationHistory.actions.goToClaimsDate());
  };

  const onChangeClinicSelection = (claimClinicParam) => {
    adobeAnalyticsTags.SELECT_VET.store.storeName = claimClinicParam?.name;
    adobeAnalyticsTags.SELECT_VET.store.storeArea = claimClinicParam?.address;
    adobeAnalyticsTags.SELECT_VET.store.storePhone = claimClinicParam?.phoneNumber;
    adobeAnalyticsTags.SELECT_VET.store.storeZip = claimClinicParam?.zipCode;
    trackEvent(adobeAnalyticsTags?.SELECT_VET);
    dispatch(claims.actions.setClaimClinic(claimClinicParam));
  };

  const onClaimDateSelected = (date) => {
    dispatch(claims.actions.setDate(date));
    dispatch(navigationHistory.actions.goToClaimsVetInvoice());
  };

  const onDoneInvoiceUpload = (file, preSignedObject) => {
    const invoiceFileWithSignedURL = {
      ...file,
      key: preSignedObject.fields.key,
    };
    dispatch(claims.actions.setFile(invoiceFileWithSignedURL));
    dispatch(navigationHistory.actions.goToClaimsReview());
  };

  const doneReviewing = async (e) => {
    e.preventDefault();
    setCallSaveClaim(true);
  };

  const backToRedeemBenefits = () => {
    trackEvent(adobeAnalyticsTags?.VETEXAM_RETURN_TO_DASHBOARD);
    dispatch(claims.actions.resetClaimState());
    dispatch(fetchMembershipPlans(true));
    dispatch(navigationHistory.actions.goBackToRedeemBenefits());
  };

  const goToClinicSelection = () => {
    dispatch(navigationHistory.actions.goToClaimsClinicSelection());
  };

  const mountToggledComponent = (vetClinicFinderVetsource, oldComponent, newComponent) => {
    if (vetClinicFinderVetsource) {
      return newComponent;
    } else {
      return oldComponent;
    }
  };

  return (
    <ClaimWrapper>
      {step === VIEW_INDEX.CLAIMS_CLINIC_SELECTION.step
        && mountToggledComponent(toggles.VET_CLINIC_FINDER_VETSOURCE,
          <ClaimClinicSelection
            petName={petName}
            claimClinic={claimClinic}
            onClinicSelectionCompletion={onClinicSelectionCompletion}
            onChangeClinicSelection={onChangeClinicSelection}
            toggles={toggles}
          />,
          <ClaimClinicSelectionV2
            petName={petName}
            claimClinic={claimClinic}
            claimLastClinicSelected={claimLastClinicSelected}
            onClinicSelectionCompletion={onClinicSelectionCompletion}
            onChangeClinicSelection={onChangeClinicSelection}
            toggles={toggles}
          />)}
      {step === VIEW_INDEX.CLAIMS_DATE.step
        && (
          <ClaimsDate
            petName={petName}
            planStartDate={planStartDate}
            claimDate={claimDate}
            onClaimDateSelected={onClaimDateSelected}
            toggles={toggles}
          />
        )}
      {step === VIEW_INDEX.CLAIMS_VET_INVOICE.step
        && mountToggledComponent(toggles.VET_CLINIC_FINDER_VETSOURCE,
          <ClaimsUploadInvoices
            claimFile={claimFile}
            onDoneInvoiceUpload={onDoneInvoiceUpload}
            claimPresignedObject={claimPresignedObject}
            claimFileSizeLimit={toggles.CLAIM_IMAGE_MAX_SIZE_BYTES}
          />,
          <ClaimsUploadInvoicesV2
            claimFile={claimFile}
            onDoneInvoiceUpload={onDoneInvoiceUpload}
            claimPresignedObject={claimPresignedObject}
            claimFileSizeLimit={toggles.CLAIM_IMAGE_MAX_SIZE_BYTES}
          />)}
      {step === VIEW_INDEX.CLAIMS_REVIEW.step
        && mountToggledComponent(toggles.VET_CLINIC_FINDER_VETSOURCE,
          <ClaimReview
            petName={petName}
            petType={petType}
            claimDate={claimDate}
            claimFile={claimFile}
            doneReviewing={doneReviewing}
            goToClinicSelection={goToClinicSelection}
            claimClinic={claimClinic}
          />,
          <ClaimReviewV2
            petName={petName}
            petType={petType}
            claimDate={claimDate}
            claimFile={claimFile}
            doneReviewing={doneReviewing}
            goToClinicSelection={goToClinicSelection}
            claimClinic={claimClinic}
          />)}
      {step === VIEW_INDEX.CLAIMS_THANK_YOU.step
        && mountToggledComponent(toggles.VET_CLINIC_FINDER_VETSOURCE,
          <ClaimsThankYou
            palsRewardsValue={palsRewardsValue}
            backToRedeemBenefits={backToRedeemBenefits}
          />,
          <ClaimsThankYouV2
            isOptimusCustomer={isOptimusCustomer}
            palsRewardsValue={palsRewardsValue}
            backToRedeemBenefits={backToRedeemBenefits}
            toggles={toggles}
          />)}
    </ClaimWrapper>
  );
};

export default Claim;
